import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/blog/best-way-to-present-your-freelance-business",
  "title": "What’s the best way to present your freelance business: Studio or Person?",
  "excerpt": "When freelancing by yourself what is the best option",
  "tags": ["web development", "templating", "gulp", "email"],
  "coverPhoto": {
    "cloudinaryAssetData": true,
    "cloudName": "dmocgebcq",
    "publicId": "portfolio/blog-covers/1_7swsczEpou_0HBar0aXpKQ_jweq5t.jpg",
    "originalHeight": 2666,
    "originalWidth": 4000,
    "defaultBase64": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mMMXG/8HwAEwAI0Bj1bnwAAAABJRU5ErkJggg==",
    "defaultTracedSVG": "data:image/svg+xml,%3Csvg%20height%3D%229999%22%20viewBox%3D%220%200%209999%209999%22%20width%3D%229999%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22m0%200h9999v9999h-9999z%22%20fill%3D%22%23f9fafb%22%2F%3E%3C%2Fsvg%3E"
  },
  "draft": false,
  "type": "blog",
  "date": "2018-01-03T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`This is a question I’ve been struggling with lately. I like the professionalism being a studio can bring. It looks better to approach clients as a studio. More people feel confident interacting with a perceived group versus a single individual. A con is not wanting to mislead people and project a bordering on false image. When creating my website, should I use a “royal we”? Or, should I use I? I’m unsure which to choose. When they get to the about page are they expecting more than me? What if I don’t tell them much about me? Then it makes it harder to connect with a client by sharing your story and such.`}</p>
    <p>{`I fear that not being a studio, no one will think I’m serious. I don’t want to people to think of me as a tool that does whatever they want regardless of the strength of the idea. I would rather be a partner in the solution. I want to be able to stand with them and help them through the problem. Not someone called when they perceive a problem and want me to take steps for them. Note, there isn’t anything wrong with that. I, myself, don’t want to be far from the idea generation. I like being apart of that side and giving my input in creating a great product.`}</p>
    <p>{`Right now my solution is to be a studio. It feels stronger appearance wise and looks better to clients. I do feel kind of like a fake though. In time, I’ll show my value and not have to worry about being misleading.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      